@value spacing: "../../styles/spacing.css";
@value xsmall, medium-small, medium, medium-large, large, xlarge from spacing;

@value colors: "../../styles/colors.css";
@value greys-100, greys-800 from colors;

@value fonts: '../../styles/fonts.css';
@value font-size-medium from fonts;

.textContainer {
	padding: xlarge medium-large medium-large medium-large;
}

.accordionContainer {
	padding: xlarge medium-large 0 medium-large;
}

.skeletonContainer {
	padding: medium-large;
}

.spacer {
	padding-bottom: xsmall;
}

.bigSpacer {
	padding-bottom: medium;
}

.spacerMedium {
	padding-bottom: medium-small;
}

.spaceDetailsContainer {
	margin-left: calc(large - 2px);
	margin-right: medium-large;
	padding-bottom: calc(large - 2px);
	padding-top: medium;
}

.spaceDetailsRow {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.spaceDetailsInformation {
	display: block;
	flex: 2;
}

.spaceDetailsGuests {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: flex-end;
}

.titleContainer {
	padding: large medium-large;
}

.divider {
	height: 1px;
	background-color: greys-100;
}

.capacity {
	white-space: nowrap;
}

.icon {
	margin-right: medium-small;
	color: #7e868d;
}

.subtitle {
	text-transform: capitalize;
	font-size: font-size-medium;
}
