@value colors: '../../styles/colors.css';
@value spacing: '../../styles/spacing.css';

@value primary, white, greys-100, greys-600, outline from colors;
@value medium from spacing;

@value fonts: '../../styles/fonts.css';
@value font-size-medium, line-height-medium from fonts;

.button {
	background: primary;
	border-radius: 4px;
	padding: 0 medium;
	height: 48px;
	border: none;
	display: block;
	font-weight: 600;
	font-size: font-size-medium;
	line-height: line-height-medium;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: white;
	width: 100%;
	outline: 0;
	cursor: pointer;
}

.button:disabled {
	opacity: 0.6;
	cursor: not-allowed;
}

.button:focus {
	outline: 0;
	box-shadow: 0 0 0 3px outline;
}
