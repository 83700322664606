@value colors: '../../styles/colors.css';
@value greys-100, greys-800 from colors;

@value fonts: '../../styles/fonts.css';
@value font-size-medium from fonts;

@value spacing: '../../styles/spacing.css';
@value medium from spacing;

.textarea {
	color: greys-800;
	height: 64px;
	width: 100%;

	padding: medium;

	border: 1px solid greys-100;
	border-radius: 6px;

	font-size: font-size-medium;

	resize: none;
	appearance: none;
}
