@value fonts: "../../styles/fonts.css";
@value font-size-small from fonts;
@value colors: "../../styles/colors.css";
@value red from colors;

.frame {
	background-color: white;
	height: 100%;
	opacity: 1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 8px;
}

.iframe * {
	line-height: line-height-medium;
}
