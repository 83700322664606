@value colors: '../../styles/colors.css';
@value fonts: '../../styles/fonts.css';
@value spacing: '../../styles/spacing.css';

@value greys-600, greys-700, greys-800 from colors;
@value error from colors;

@value font-weight-normal from fonts;
@value font-weight-bold from fonts;

@value line-height-small from fonts;
@value line-height-medium from fonts;

@value font-size-small from fonts;
@value font-size-medium from fonts;
@value font-size-large from fonts;
@value font-size-extra-large from fonts;

@value small from spacing;

.title {
	font-weight: font-weight-bold;
	font-size: font-size-extra-large;
	line-height: line-height-small;
	color: greys-800;
	margin: 0;
}

.sectionTitle {
	font-weight: font-weight-bold;
	font-size: font-size-large;
	line-height: line-height-medium;
	color: greys-800;
	margin: 0;
}

.subtitle {
	font-weight: font-weight-bold;
	font-size: font-size-small;
	line-height: line-height-medium;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: greys-800;
	margin: 0;
}

.boldText {
	font-weight: font-weight-bold;
	font-size: font-size-medium;
	line-height: line-height-medium;
	color: greys-800;
}

.smallBoldText {
	font-weight: font-weight-bold;
	font-size: font-size-small;
	line-height: line-height-medium;
	color: greys-800;
}

.emphasizedText {
	font-weight: font-weight-bold;
	font-size: font-size-medium;
	line-height: line-height-medium;
	color: greys-800;
	text-decoration: underline;
}

.text {
	font-weight: font-weight-normal;
	font-size: font-size-medium;
	line-height: line-height-medium;
	color: greys-600;
}
.text svg {
	vertical-align: text-top;
}

.errorText {
	font-weight: font-weight-normal;
	font-size: font-size-medium;
	line-height: line-height-medium;
	color: error;
}

.label {
	font-weight: font-weight-bold;
	font-size: font-size-medium;
	line-height: line-height-medium;
	margin-bottom: small;
	color: greys-800;
}

.help {
	font-weight: font-weight-normal;
	font-size: font-size-small;
	color: greys-700;
	margin-bottom: small;
	line-height: line-height-medium;
}

.truncate {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
