@value colors: '../../styles/colors.css';
@value white, error from colors;

@value spacing: '../../styles/spacing.css';
@value medium-small, medium from spacing;

.errorClass {
	position: fixed;
	bottom: medium;
	left: medium;
	right: medium;

	display: flex;
	align-items: center;
	justify-content: space-between;

	padding: medium-small;

	background-color: error;
	color: white;

	border-radius: 2px;

	animation: fadein 0.6s ease 0.6;
}

.closeButton {
	padding: medium;
	cursor: pointer;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
