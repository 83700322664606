@value colors: '../../styles/colors.css';
@value greys-100, greys-600, greys-800, outline from colors;

@value fonts: '../../styles/fonts.css';
@value font-size-medium from fonts;

@value spacing: '../../styles/spacing.css';
@value medium, small from spacing;

:global(.PhoneInputCountryIcon--border) {
		background-color: transparent;
		box-shadow: none;
	}

.popup {
	position: absolute;
	z-index: 1000;
	border: 1px solid greys-100;
	background: white;
	height: 200px;
	overflow: hidden;
	border-radius: 4px;
	box-shadow: 0 1px 10px rgba(0, 0, 0, .06);
}

.button {
	height: 42px;
	width: 42px;
	border: 1px solid #E4E7EA;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	cursor: pointer;
	margin-right: -1px;
	transition: background-color 300ms;
	background-color: white;
}
button:hover {
	background: greys-100;
}
