@value colors: "../../styles/colors.css";
@value white, greys-100 from colors;

@value spacing: "../../styles/spacing.css";
@value xsmall, small, medium-small, medium,medium-large, large, xlarge, xxlarge from spacing;

.container {
	padding: medium-large;
}

.title {
	margin: medium-small 0 xxlarge 0;
}

.summary {
	flex-direction: row;
	display: flex;
	margin-bottom: large;
}

.summaryText {
	flex-direction: column;
	display: flex;
	flex: 3;
	justify-content: space-around;
}

.spacer {
	height: xsmall;
}

.image {
	margin-right: medium-small;
}

.summary {
	flex-direction: row;
	display: flex;
}

.summaryText {
	flex-direction: column;
	display: flex;
	flex: 3;
}

.emailInputContainer {
	padding-bottom: medium;
}

.image {
	border-radius: 2px;
	margin-right: medium-small;
}

.pricing {
	padding: medium-small medium;
}

.form {
	margin-bottom: large;
}

.emailConfirmation {
	margin: 0 0 small 0;
}

.explanation {
	white-space: break-spaces;
}

.button {
	margin-bottom: xxlarge;
}

.divider {
	height: 1px;
	background-color: greys-100;
	margin: medium-small 0;
}
