@value colors: '../../styles/colors.css';
@value greys-100, greys-600, greys-800, outline from colors;

@value fonts: '../../styles/fonts.css';
@value font-size-medium from fonts;

@value spacing: '../../styles/spacing.css';
@value medium, small from spacing;

.container {
	border-radius: 6px;
	border: 1px solid greys-100;
	display: flex;
	align-items: stretch;
	position: relative;
	width: 100%;
}

.container.borderless {
	border: none;
}

.input {
	background-color: white;
	outline: 0;
	color: greys-800;
	height: 40px;
	padding: 0 small;
	border: none;
	border-radius: 6px;
	font-size: font-size-medium;
	line-height: 1;
	appearance: none;
	flex: 1;
	min-width: 0;
}

.borderless .input {
	height: 38px;
}

.container:not(.borderless) .input:focus {
	outline: 0;
	box-shadow: 0 0 0 3px outline;
}

.iconContainer {
	align-items: center;
	display: flex;
	width: 16px;
	margin-left: medium;
}

.postfix {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: medium;
	align-self: center;
	color: greys-800;
	font-size: font-size-medium;
}
