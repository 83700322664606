@value fonts: "../../styles/fonts.css";
@value font-size-small, font-size-medium, font-size-large, font-weight-bold from fonts;
@value spacing: "../../styles/spacing.css";
@value xsmall, small, medium-small, medium from spacing;
@value colors: "../../styles/colors.css";
@value greys-100 from colors;

.container {
	position: relative;
	margin: medium 0;
}

.image {
	display: block;
	height: 0;
	padding-bottom: 75%; /* 4:3 ratio */
	background-color: greys-100;
}

.bars {
	padding-top: medium-small;
	padding-bottom: xsmall;
}

.topBar,
.bottomBar {
	border-radius: 3px;
}

.topBar {
	height: 13px;
	margin-top: 5px;
	background-color: greys-100;
}

.bottomBar {
	width: 50%;
	margin: 8px 0 2px;
	height: 12px;
	background-color: greys-100;
}

.imager,
.topBar,
.bottomBar {
	position: relative;
}

.image::after,
.topBar::after,
.bottomBar::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: translateX(-100%);
	background-image: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0,
		rgba(255, 255, 255, 0.15) 20%,
		rgba(255, 255, 255, 0.4) 60%,
		rgba(255, 255, 255, 0)
	);
	animation: shimmer 2s infinite;
	content: '';
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}
