@value colors: '../../styles/colors.css';
@value spacing: '../../styles/spacing.css';
@value fonts: '../../styles/fonts.css';

@value font-size-medium, line-height-medium from fonts;
@value xxlarge, medium, medium-small from spacing;
@value greys-800 from colors;

@keyframes rotateSpinner {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

.loader {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
	background: white;
	border: 1px solid #ebedef;
	border-radius: 90px;
	width: 280px;
	height: 80px;
	padding: medium;
	display: flex;
	align-items: center;
	position: fixed;
	bottom: xxlarge;
	left: calc(100vw / 2 - 140px);
	transform: translateY(calc(100% + xxlarge));
	transition: transform 0.5s ease-out;
	z-index: 10;
}

.loader.active,
.loader.active.withFooter {
	transform: translateY(0);
	transition-timing-function: ease-in;
}

.loader.withFooter {
	transform: translateY(calc(100% + 98px));
	bottom: 98px;
}

.spinnerWrapper {
	position: relative;
	height: 48px;
	width: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex: none;
}

.spinner {
	position: absolute;
	transform-origin: center;
	animation: rotateSpinner 0.6s linear infinite;
	width: 100%;
	height: 100%;
}

.text {
	margin-left: medium-small;
	color: greys-800;
	font-size: font-size-medium;
	line-height: line-height-medium;
}
