@value colors: "../../styles/colors.css";
@value white, greys-100 from colors;

@value spacing: "../../styles/spacing.css";
@value medium from spacing;

.container {
	background-color: white;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.body {
	flex: 1;
	overflow-x: hidden;
}

.footer {
	padding: medium;
	border: 1px solid greys-100;
}
