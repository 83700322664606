@value spacing: "../../styles/spacing.css";
@value medium-large, xxlarge from spacing;

.container {
	padding: 0 medium-large medium-large medium-large;
}

.title {
	margin: xxlarge 0;
}

.emptyStateContainer {
	margin: medium-large 0;
	text-align: center;
}

.backLink {
	cursor: pointer;
	padding-top: medium-large;
}
