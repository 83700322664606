@value spacing: "../../styles/spacing.css";
@value small, medium, medium-large, large from spacing;

@value colors: "../../styles/colors.css";
@value primary, secondary, greys-200 from colors;

.wrapper label {
	display: flex;
	user-select: none;
}

.wrapper.disabled {
	opacity: 0.4;
	cursor: default;
}

.radio {
	position: relative;
}

.radio.withLabel {
	margin-right: small;
}

.radio.selected .check,
.radio.selected .circle {
	opacity: 1;
}

.check {
	position: absolute;
	color: secondary !important;
	opacity: 0;
}

.circle {
	position: absolute;
	background: var(--primary-color);
	border-radius: 100%;
	opacity: 0;
	transform: scale(0.35);
}

.icon {
	color: greys-200;
	height: large;
	width: large;
	z-index: 0;
}
