@value colors: "../../styles/colors.css";
@value white, greys-100 from colors;

@value spacing: "../../styles/spacing.css";
@value xsmall, small, medium, large from spacing;

@value fonts: '../../styles/fonts.css';
@value font-size-medium, font-weight-bold from fonts;

.container {
	font-size: font-size-medium;
}

.row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: xsmall 0;
}

.row > :first-child {
	padding-right: large;
}

.divider {
	width: 100%;
	height: 16px;
}

.total {
	font-weight: font-weight-bold;
}
