@value colors: '../../styles/colors.css';
@value white, greys-200, secondary from colors;

@value fonts: '../../styles/fonts.css';
@value font-size-small from fonts;

@value spacing: '../../styles/spacing.css';
@value medium from spacing;

.wrapper {
	display: block;
	position: relative;
}

.value {
	box-shadow: 0 0 0 1px #e6e8ed;
	border-radius: 13px;
	width: 74px;
	color: #808080;
	background-color: white;
	outline: 0;
	text-align: center;
	font-size: 12px;
	display: flex;
	align-items: baseline;
	justify-content: flex-start;
	padding: 6px;
}

.selected {
	color: secondary;
	font-weight: bold;
	box-shadow: 0 0 0 2px secondary;
}

.select {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	opacity: 0;
	appearance: none;
}

.icon {
	margin-right: 15px;
	color: #808080;
	width: 14px;
	height: 14px;
	margin-left: 5px;
}
