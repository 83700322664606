@value spacing: '../../styles/spacing.css';
@value medium-small, medium from spacing;

.wrapper {
	display: flex;
	margin-bottom: medium;
}

.icon {
	display: flex;
	align-items: center;
	margin-right: medium-small;
}

.capacity {
	display: flex;
	flex-direction: column;
}
