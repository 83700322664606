@value spacing: "../../styles/spacing.css";
@value xsmall, medium-large from spacing;

@value colors: "../../styles/colors.css";
@value white, secondary, secondary-transparent, greys-200, greys-100 from colors;

.cell {
	background: white;
	border-top: 1px solid greys-100;
	padding: medium-large;
	position: relative;
	cursor: pointer;
}

.cell:last-child {
	border-bottom: 1px solid greys-100;
}

.selected {
	border-bottom-color: transparent;
}

.spacer {
	padding: medium-large;
}

.titleContainer {
	flex: 8;
}

.tooltipContainer {
	position: relative;
	width: calc(100% + 33%);
}

.tooltipText {
	border-bottom: 2px dotted greys-100;
	display: inline-block;
	text-transform: lowercase;
	font-size: 16px;
}

.tooltipText::first-letter {
	text-transform: capitalize;
}

.radioContainer {
	align-items: flex-start;
	display: flex;
	flex: 1;
	justify-content: flex-end;
}

.flex {
	display: flex;
	margin-bottom: medium-large;
}
