@value colors: "../../styles/colors.css";
@value primary, white, greys-600, greys-100 from colors;

@value fonts: "../../styles/fonts.css";
@value font-weight-bold from fonts;

@value spacing: '../../styles/spacing.css';
@value small, medium-small, medium-large from spacing;

.container {
	display: flex;
	flex-direction: column;
	border: 1px solid greys-100;
	border-radius: 8px;
	overflow: hidden;
}

.row {
	display: flex;
	border-bottom: 1px solid greys-100;
}

.leftColumn {
	border-right: 1px solid greys-100;
	flex: 1;
}

.rightColumn {
	border-right: none;
	flex: none;
	width: 100px;
}
