@value colors: '../../styles/colors.css';
@value white, greys-100, greys-800, outline from colors;

@value fonts: '../../styles/fonts.css';
@value font-size-medium, line-height-medium from fonts;

@value spacing: '../../styles/spacing.css';
@value medium from spacing;

.wrapper {
	display: block;
	position: relative;

	border: 1px solid greys-100;
	border-radius: 2px;
}

.wrapper.borderless {
	border: none;
}

.borderless .input {
	height: 38px;
}

.select {
	color: greys-800;
	background-color: white;
	outline: 0;
	border: none;
	height: 40px;
	padding: 0 38px 0 medium;

	font-size: font-size-medium;
	line-height: line-height-medium;

	appearance: none;
	width: 100%;
}

.select:focus {
	outline: 0;
}

.wrapper:not(.borderless) .select:focus {
	box-shadow: 0 0 0 3px outline;
}

.caret {
	position: absolute;
	right: medium;
	top: calc(50% - 12px);
	pointer-events: none;
}
