@value spacing: "../../styles/spacing.css";
@value xsmall, small, medium, medium-large from spacing;

@value colors: "../../styles/colors.css";
@value white, primary, greys-100, greys-100, greys-600 from colors;

@value fonts: "../../styles/fonts.css";
@value font-weight-bold from fonts;

.container {
	border-top: 1px solid greys-100;
}

.container:last-child {
	border-bottom: 1px solid greys-100;
}

.titleContainer {
	padding: medium-large 0px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title {
	font-weight: font-weight-bold;
}

.extendable {
	padding-bottom: small;
}

.container:not(.selected) .extendable {
	display: none;
}

.item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.item + .item {
	margin-top: xsmall;
}

.item:last-child {
	margin-bottom: medium-large;
}

.chevronContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	transform-origin: center center;
}

.container.selected .chevronContainer {
	transform: rotate(180deg);
}
