@value spacing: "../../styles/spacing.css";
@value xsmall, small, medium-small, medium-large, medium from spacing;

@value colors: "../../styles/colors.css";
@value white, primary, secondary, secondary-transparent, greys-100, greys-800 from colors;

@value fonts: "../../styles/fonts.css";
@value font-size-medium from fonts;

.image {
	border-radius: 2px;
	object-fit: cover;
	width: 100%;
}

.image.wide {
	height: calc(75vw - 20px);
}

.image.square {
	height: calc(100vw - 20px);
}

.slide {
	position: relative;
	outline: none;
}

.slide .image {
	outline: none;
}

.container {
	position: relative;
}

.overlayContainer > .indicator {
	background-color: greys-800;
	color: white;
	position: absolute;
	z-index: 50;
	padding: small medium-small;
	border-radius: 2px;
	color: primary;
	bottom: 5px;
	right: 5px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.indicator span {
	font-size: font-size-medium;
	margin-left: small;
	color: white;
}

.overlayContainer {
	position: absolute;

	top: 0px;
	left: 0px;

	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: space-between;
	color: white;
}

.overlayContainer > button {
	height: 48px;
	width: 32px;
	border: none;
	background-color: white;
	opacity: 0.8;
	outline: none;
}

.previousButton {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.nextButton {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}
