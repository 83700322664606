@value colors: '../../styles/colors.css';
@value spacing: '../../styles/spacing.css';
@value fonts: '../../styles/fonts.css';

@value xsmall, medium from spacing;
@value greys-100, greys-600, white from colors;
@value xsmall, medium, medium-large from spacing;
@value font-size-small, font-size-large, font-weight-bold, line-height-large, font-size-medium from fonts;

.summary {
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.editIcon {
	width: 32px;
	margin: 0 40px 0 20px;
	background: none;
	border: none;
	padding: 8px;
	cursor: pointer;
}

.subtitle {
	display: block;
}
