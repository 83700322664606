.promoLink {
	color: rgba(0, 0, 0, 0.7);
	position: relative;
	font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    margin: 0 auto;
    user-select: none;
}

.container {
	background: white;
    position: relative;
    z-index: 1;
    padding: 0 8px;
}

.promoLink a {
	color: rgba(0, 0, 0, 0.7);
	text-decoration: underline;
	cursor: pointer;
}

.divider {
	background: #ededed;
	height: 1px;
	position: absolute;
    left:0;
    right: 0;
}
