@value spacing: "../../styles/spacing.css";
@value xsmall, medium-large from spacing;

@value colors: "../../styles/colors.css";
@value white, primary, secondary, secondary-transparent, greys-100, greys-600 from colors;

.cell {
	background: white;
	border: 1px solid greys-100;
	padding: medium-large;
	position: relative;
	cursor: pointer;
}

.spacer {
	padding: medium-large 0 medium-large;
}

.titleContainer {
	flex: 8;
}

.ul {
	padding: 0;
	color: greys-100;
	margin: 0;
	list-style-type: none;
}

.li {
	margin-top: 12px;
	line-height: 25.2px;
	position: relative;
}

.li:first-child {
	margin-top: 0;
}

.icon {
	display: flex;
	align-items: center;
	margin-right: medium-small;
	color: #7e868d;
	margin-right: 13.4px;
}

.radioContainer {
	align-items: flex-start;
	display: flex;
	flex: 1;
	justify-content: flex-end;
}

.flex {
	display: flex;
}

.tooltip {
	position: relative;
}

.tooltipContainer {
	position: relative;
	width: calc(100% + 12px);
}

.tooltip .text {
	border-bottom: 0.5px dashed rgba(82, 96, 108, 0.5);
}

.text {
	white-space: nowrap;
	max-width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.subtitle {
	font-size: 16px;
	text-transform: initial;
}

.toTowerCase {
	text-transform: lowercase;
	font-weight: bold;
	line-height: 150%;
	color: #3e4c58;
}
