.container {
	display: flex;
	flex-wrap: wrap;
}

.setup {
	flex: 0 50%;
	width: 100%;
	color: #9aa5b0;
}
