@value fonts: "../../styles/fonts.css";
@value font-size-small, font-size-medium, font-size-large, font-weight-bold from fonts;
@value spacing: "../../styles/spacing.css";
@value xsmall, small, medium-small, medium, large from spacing;
@value colors: "../../styles/colors.css";
@value greys-100 from colors;

.container {
	position: relative;
	margin: medium 0;
	display: flex;
}

.checkbox {
	height: large;
	width: large;
	border-radius: 100%;
	background-color: greys-100;
	flex: none;
}

.bars {
	flex: 1;
	margin-right: 120px;
}

.bar {
	border-radius: 3px;
	height: medium-small;
	background-color: greys-100;
	margin-bottom: medium-small;
}

.bar1 {
	width: 80%;
}
.bar2 {
	width: 65%;
}
.bar3 {
	width: 100%;
}
.bar4 {
	width: 40%;
}

.checkbox,
.bar {
	position: relative;
}

.bar::after,
.checkbox::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: translateX(-100%);
	background-image: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0,
		rgba(255, 255, 255, 0.15) 20%,
		rgba(255, 255, 255, 0.4) 60%,
		rgba(255, 255, 255, 0)
	);
	animation: shimmer 2s infinite;
	content: '';
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}
