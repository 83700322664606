@value font-size-small: 12px;
@value font-size-medium: 14px;
@value font-size-large: 18px;
@value font-size-extra-large: 24px;

@value line-height-small: 1.2em;
@value line-height-medium: 1.5em;

@value font-weight-bold: 700;
@value font-weight-normal: 400;
