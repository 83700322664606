@value colors: "../../styles/colors.css";
@value secondary from colors;

@value spacing: '../../styles/spacing.css';
@value small, medium-small, medium-large, large from spacing;

@value fonts: '../../styles/fonts.css';
@value font-weight-bold from fonts;

.header {
	height: 60px;
}

.container {
	padding: 0 medium-large;
}

.title {
	padding: large 0;
}

.explanation {
	padding-bottom: medium-large;
}

.boldUnderline {
	font-weight: font-weight-bold;
	text-decoration: underline;
}

.checkmarkRow {
	display: flex;
	align-items: center;
	padding-bottom: small;
}

.checkmark {
	color: secondary;
}

.rowText {
	padding-left: medium-small;
}

.email {
	padding-top: medium-small;
}
