@value colors: '../../styles/colors.css';
@value spacing: '../../styles/spacing.css';
@value fonts: '../../styles/fonts.css';

@value xsmall, medium from spacing;
@value greys-100, greys-600, white from colors;
@value xsmall, medium, medium-large from spacing;
@value font-size-small, font-size-large, font-weight-bold, line-height-large from fonts;

.navHeader {
	min-height: 60px;
}

.main {
	position: relative;
	display: flex;
	flex: 1;
	border-bottom: 1px solid greys-100;
}

.summary {
	background-color: white;
	position: fixed;
	display: flex;
	align-items: center;
	height: 60px;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	box-shadow: 0 1px rgba(0, 0, 0, 0.08);
	transform: translateY(-100%);
	transition: transform 0.2s ease-out;
}

.summary.showSummary {
	transform: translateY(0%);
	transition: transform 0.2s ease-in;
}

.summary:not(.noContent) .buttonContainer {
	border-right: 1px solid greys-100;
}

.buttonContainer {
	width: 56px;
	min-height: 60px;
	flex: none;
	position: relative;
}

.button {
	width: 56px;
	height: 60px;
	padding: 12px;
	color: greys-600;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.navHeader .button {
	top: 12px;
}

.content {
	flex: 1;
}

.summary .content {
	padding-left: medium-large;
}

.navHeader .content {
	padding: medium-large medium-large medium-large 0;
}
