@value colors: '../../styles/colors.css';
@value greys-800 from colors;

@value fonts: '../../styles/fonts.css';
@value font-size-small from fonts;

@value spacing: '../../styles/spacing.css';
@value medium-small from spacing;
@value xsmall from spacing;

.field {
	margin-top: medium-small;
	position: relative;
}

.label {
	display: block;
}

.innerField {
	margin-top: xsmall;
}

.error {
	margin-top: xsmall;
}

.absoluteError {
	position: absolute;
	top: 100%;
	left: 0;
}
