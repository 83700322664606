.datepicker {
	border: none;
	width: 100%;
}

.datepicker .react-datepicker__month-container {
	float: none;
}

.datepicker .react-datepicker__month {
	margin: 0 0 24px 0;
}

.datepicker .react-datepicker__header {
	background: none;
	border: none;
}

.datepicker .react-datepicker__day-name {
	width: 48px;
	margin: 0;
}

.datepicker .react-datepicker__day {
	font-size: 14px;
	font-weight: bold;
	color: #47525c;
	height: 48px;
	width: 48px;
	border: 1px solid #E4E7EA;
	margin: 0 -1px -1px 0;
	border-radius: 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.datepicker .react-datepicker__day--selected,
.datepicker .react-datepicker__day--selected.react-datepicker__day--today {
	background-color: var(--primary-color);
	color: var(--text-color);
}

.datepicker .react-datepicker__day--today {
	font-weight: normal;
	background: none;
}

.datepicker .react-datepicker__day--disabled {
	text-decoration: line-through;
	font-weight: normal;
	opacity: 0.3;
}

.datepicker .react-datepicker__day--keyboard-selected {
	color: inherit;
	background-color: inherit;
	border: 1px solid #e4e7ea;
	border-style: dashed;
}

..datepicker react-datepicker__portal {
	background-color: transparent;
}

.datepicker .react-datepicker__day-name {
	opacity: 0.5;
	color: #000000;
}

.datepicker .react-datepicker__day--outside-month {
	visibility: hidden;
}
