@value colors: '../../styles/colors.css';
@value yellow, greys-100, greys-600, greys-800, outline from colors;

@value fonts: '../../styles/fonts.css';
@value font-size-medium, font-size-small, font-weight-bold from fonts;

@value spacing: '../../styles/spacing.css';
@value medium, large from spacing;

.modalContent {
	padding: 20px;
}

.applyButton {
	padding-bottom: large;
}

/* Close button */
.closeButtonContainer {
	width: 100%;
	margin: 0 0 10px 6px;
}

.closeButton {
	background: none;
	border: none;
	cursor: pointer;
	width: 30px;
	padding: 10px;
}

/* Date picker */
.datePickerHeader {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.datePickerHeader .currentDate {
	font-size: 24px;
	font-weight: 700;
	color: #47525c;
	line-height: 28px;
	flex: 1;
}

.datePickerHeader .monthNavigateButton {
	background: none;
	border: 1.5px solid #e4e7ea;
	height: 40px;
	width: 40px;
	border-radius: 100%;
	cursor: pointer;
	line-height: 1;
}

/* Time selection */
.slots {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	margin: large 0;
}

.time {
	margin-bottom: large;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.time > * {
	flex: 1;
}

.dayContents {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.partialAvailability {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
}

.legend {
	display: flex;
	align-items: center;
	margin-bottom: 12px;
	user-select: none;
	position: relative;
}
.legend .partialAvailability {
	position: static;
}

.legendLabel {
	margin-left: 8px;
	font-size: font-size-small;
	font-weight: font-weight-bold;
	color: greys-800;
}
