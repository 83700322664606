@value fonts: "../../styles/fonts.css";
@value font-size-small, font-size-medium, font-size-large, font-weight-bold from fonts;
@value spacing: "../../styles/spacing.css";
@value medium-small, medium-large, large, xlarge from spacing;
@value colors: "../../styles/colors.css";
@value greys-100 from colors;

/* You might need to also update the Skeleton component's CSS when updating
the CSS below, as the Skeleton should resemble this component */
.container {
	position: relative;
	cursor: pointer;
}

.container + .container {
	margin-top: xlarge;
}

.textContainer {
	margin-top: medium-small;
	display: flex;
	justify-content: space-between;
	position: relative;
}

.textContainer {
	margin-top: medium;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.capacityContainer {
	display: flex;
	align-items: center;
}

.capacity {
	white-space: nowrap;
}

.icon {
	margin-right: medium-small;
	color: #7e868d;
}

.subtitle {
	font-size: 14px;
	text-transform: none;
}
