@value fonts: "../../styles/fonts.css";
@value font-size-small, font-size-medium, font-size-large, font-weight-bold from fonts;
@value spacing: "../../styles/spacing.css";
@value medium, medium-large, large, xlarge from spacing;
@value colors: "../../styles/colors.css";
@value greys-100, greys-800 from colors;
@value medium-small, medium, medium-large, large from spacing;

.container {
	padding: medium medium medium-large medium;
	width: calc(100%);
	background-color: greys-800;
	position: absolute;
	transform: translateY(-100%);
	top: calc(-1 * medium);
	left: calc(-0.3 * medium-large);
	border-radius: 2px;
}

.container::after {
	content: '';

	width: 15px;
	height: 15px;
	position: absolute;

	transform: rotate(45deg);

	background-color: greys-800;

	/* Invisible */
	border-left: 1px solid greys-800;

	bottom: -6px;
	left: 60px;
}

.delay_tooltip {
	visibility: hidden;
	animation: delayDisplay 0s 0.5s forwards;
}

@keyframes delayDisplay {
	to {
		visibility: visible;
	}
}

.title {
	display: block;
	font-weight: font-weight-bold;
	color: white;
}

.description {
	color: white;
}

.hidden {
	display: none;
}

.divider + div {
	margin-top: medium-small;
}
