@value spacing: "../../styles/spacing.css";
@value medium-large, large from spacing;

.container {
	padding: large medium-large medium-large medium-large;
}

.container > *:not(:last-child) {
	margin-bottom: medium-large;
}

.header {
	padding: large medium-large 0 medium-large;
	display: flex;
	align-items: center;
}

.button {
	padding: 0 medium-large;
	margin-left: calc(-1 * medium-large);
	cursor: pointer;
}
