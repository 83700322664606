.modal {
	background-color: white;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 101;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	transition: transform 0.2s 0.2s linear;
	transform: translateY(100%);
}

.modal.visible {
	transform: translateY(0%);
	transition: transform 0.2s linear;
}
